import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";

function Unsettledbet() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [betUnsettledData, setBetUnsettledData] = useState([]);
  const username = localStorage.getItem('username');
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reportType, setReportType] = useState(1); 
  const [selectedSport, setSelectedSport] = useState('');
  const filteredData = betUnsettledData.filter(item => {

    const isStatusMatch = !selectedStatus || item.bet_type === selectedStatus;
    return isStatusMatch;
  });
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleEntriesChange = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };
  const fetchDataFromAPI = async () => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl = `https://leobook.in/Admin/api/v1/unselleted-bet?start_date=${fromDate}&end_date=${toDate}`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data from the API");
      }

      const data = await response.json();
      console.log(data.data);
      setBetUnsettledData(data.data);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, []);
  const handleFilterChange =  () => {
    fetchDataFromAPI();
  };
  const handleFilterReset =  () => {
    setFromDate("")
    setToDate("")
  fetchDataFromAPI();
 window.location.reload();
  };
  const handleLinkClick = () => {
    navigate('/Home');
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
    
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours.toString().padStart(2, '0');
  
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
  };
  return (
    <>
      {/* <div class="card-header tab_first d-flex" style={{ height: '55px' }}></div> */}
      <section className="padding_15">
        <main id="main" class="main bethistory">
          <div class="accountstatement d-flex justify-content-between">
            <h5 className='text-white'>Unsetteled Bet </h5>
            <button className="text-light btn bg-success text-info" onClick={handleLinkClick}>Back</button>
          </div>
          <div className="account_statement mt-2">
              <div className="d-flex align-items-end flex-wrap-mobile">
                  <div className="d-flex justify-content-between align-items-end width_100">
                  <Form.Select className="selectbet" aria-label="Default select example">
                {/* <option>Sport Type</option> */}
                <option value="1">Cricket</option>
                {/* <option value="2">Tennis</option>
                <option value="3">FootBall</option> */}
             
              </Form.Select>
              <Form.Select className="selectbet mx-1" aria-label="Default select example" onChange={handleStatusChange}>
  <option value="">Bet Status</option>
  <option value="fancy">Fancy</option>
  <option value="bookmaker">Bookmaker</option>
  <option value="match_odds">Match Odds</option>
</Form.Select>
                  </div>
                  <form id="search" role="form" className="mb-0"> 
                      <div class="row d-flex align-items-end">
                        <div class="col-md-4 col-6">
                          <label>From Date</label>
                          <input
                            type="date"
                            class="form-custum"
                         
                            id="dateFrom"
                            value={fromDate} onChange={(e) => setFromDate(e.target.value)}
                          />
                        </div>

                        <div class="col-md-4 col-6">
                          <label>To Date</label>
                          <input
                            type="date"
                            class="form-custum"
                        
                            id="dateTo"
                            value={toDate} onChange={(e) => setToDate(e.target.value)}
                          />
                        </div>

                        <div class="col-md-4 col-12 d-flex align-items-flex-end gap-2 mt-3">
                          <button
                            class="btn_custom btn bg-color"
                            type="button"
                            id="getJsonSrc"
                            onClick={handleFilterChange}
                          >
                            {/* <i class="fa-solid fa-magnifying-glass pr-5"></i> */}
                            Go
                          </button>
                          <button
                            class="btn_custom btn bg-color"
                            type="button"
                            id="getJsonSrc"
                            onClick={handleFilterReset}
                          >
                            {/* <i class="fa-solid fa-magnifying-glass pr-5"></i> */}
                            Reset
                          </button>
                        </div>
                      </div>
                    </form>
              </div>
          </div>
          <div className="entries-selector text-light d-flex gap-1 mt-2 align-items-center">
            <span>Show :</span>
            <select
              id="entries"
              value={itemsPerPage}
              onChange={handleEntriesChange}
              className="bg-success text-light p-2  col-md-1"

            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </select>
            {/* <span>entries</span> */}
          </div>
          <div class="account_statement">
            <div class="">

              <div class="mt-5">
                <div class="table-responsive">
                  <table class="table profit-loss-table accountstmt">
                    <thead>
                      <tr>
                        <th className="col-xs-1" data-field="date">
                          S.No.
                        </th>
                        <th className="col-xs-1" data-field="date">
                          Event Type
                        </th>
                        <th
                          className="col-xs-1"
                          data-field="operator"
                        >
                          Event Name
                        </th>
                        {/* <th className="col-xs-2 wid280" data-field="type">
                                          UserName
                                        </th> */}
                        <th
                          className="col-xs-2"
                          data-field="subType"
                        >
                          Runner Name
                        </th>
                        <th
                          className="col-xs-5"
                          data-field="message"
                        >
                          Bet Type
                        </th>
                        <th
                          className="col-xs-5"
                          data-field="message"
                        >
                          User Rate
                        </th>
                        <th
                          className="col-xs-5"
                          data-field="message"
                        >
                          Amount
                        </th>
                        <th
                          className="col-xs-5"
                          data-field="message"
                        >
                          Place Date
                        </th>
                        <th className="col-xs-5" data-field="message">
                          Match Date

                        </th>
                      </tr>
                    </thead>
                    {/* <tbody>
                        {betUnsettledData.map((item) => (
                          <tr key={item.id}>
                            <td>NULL</td>
                            <td>{item.event_name}NULL</td>
                            <td>NULL</td>
                            <td>NULL</td>
                            <td>{item.bet_on}</td>
                            <td>{item.stake}</td>
                            <td>{item.created_at}</td>
                           
                          </tr>
                        ))}
                      </tbody> */}


                    <tbody>
                      {currentItems && currentItems.map((item,index) => (
                        <tr key={index}>
                                                                      <td>{indexOfFirstItem  + index  + 1}</td>

                          <td>{item.event_id}</td>
                          <td>{item.event_name ? item.event_name.name : 'N/A'}</td>
                          {/* <td>{username}</td> */}
                          <td>{item.team}</td>
                          <td>{item.bet_type}</td>
                          <td >{item.total}</td>
                          <td >{item.stake}</td>
                          <td >{formatDate(item.created_at)}</td>
                          <td >{formatDate(item.created_at)}</td>
                      
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="pagination">
                                  {Array.from({ length: totalPages }, (_, i) => (
                                    <button
                                      key={i}
                                      onClick={() => handlePageChange(i + 1)}
                                      className={currentPage === i + 1 ? "active" : ""}
                                    >
                                      {i + 1}
                                    </button>
                                  ))}
                                      </div>
              </div>
              {/* <div class="tab__content-itemthird">
                  <div class="table-responsive">
                    <table class="table profit-loss-table">
                      <thead>
                        <tr>
                          <th><span>Placed</span></th>
                          <th>Description</th>
                          <th>Type</th>
                          <th class="text-right">Odds</th>
                          <th class="text-right">Stake</th>
                          <th class="text-right"><span>Liability</span> </th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="7" class="text-center">There are no record to display
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div> */}




            </div>
          </div>

        </main>
      </section>



    </>

  )
}

export default Unsettledbet;