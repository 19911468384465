import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Headfoot.scss";
import axios from "axios";
import ModalImage from "../assets/images/bg.gif";
import Slider from "react-slick";
import { IoMdNotifications } from "react-icons/io";
import Badge from "react-bootstrap/Badge";
import whatsappicon from "../assets/images/whatsappicon.png";
export default function Header() {
  const [show, setShow] = useState(false);
  const [whatsapp, setwhatsapp] = useState([]);

  const token = localStorage.getItem("token");
  useEffect(() => {
    // Show the modal when the component mounts
    setShow(true);
  }, []);

  const handleClose = () => setShow(false);

  const [broadcast, setBroadcast] = useState([]);
  const [Broadcastlengtrh, setBroadcastlengtrh] = useState(0);
  const [base_url, setbase_url] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchSportData();
  }, []);

  const fetchSportData = async () => {
    try {
      const response = await axios.post(
        "https://leobook.in/Admin/api/v1/latest-boardcast",
        {}, // You can pass any data here if needed, or an empty object if not
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.warn("Fetched Data:", response.data);
      setbase_url(response.data.base_url);
      setBroadcast(response.data.data);
      setBroadcastlengtrh(response.data.data.length);
    } catch (error) {
      const errorMsg = error.response
        ? error.response.data.message
        : error.message;
      setError("Error fetching sport data: " + errorMsg);
      console.error("Error fetching sport data:", error);
    } finally {
      setLoading(false);
    }
  };
  const Seenbroadcast = async (id) => {
    try {
      if (!token) {
        throw new Error("Authorization token is missing");
      }

      setLoading(true);

      const formData = new FormData();
      formData.append("broadcast_id", id);

      const response = await axios.post(
        "https://leobook.in/Admin/api/v1/boardcast_seen",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // 'Content-Type': 'multipart/form-data',
          },
        }
      );
      fetchSportData();
      // Process and log the response data
      console.warn("Fetched Data:", response);
      setBroadcast(response);
    } catch (error) {
      // Handle errors and set error message
      const errorMsg = error.response
        ? error.response.data.message
        : error.message;
      setError("Error fetching sport data: " + errorMsg);
      console.error("Error fetching sport data:", error);
    } finally {
      // Ensure loading state is stopped
      setLoading(false);
    }
  };

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: Broadcastlengtrh > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: Broadcastlengtrh > 1,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [PhoneSupport, setPhoneSupport] = useState([]);
  const [PhoneSupport1, setPhoneSupport1] = useState([]);
  useEffect(() => {
    fetchGenralSetting();
  }, []);
  const fetchGenralSetting = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const headers = {
      "Content-Type": "application/json",
    };

    fetch(`${process.env.REACT_APP_API_URL}normal-setting`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setPhoneSupport(data.data.mobile);
        setwhatsapp("https://wa.me/+" + data.data.mobile);
        setPhoneSupport1("tel:" + data.data.mobile);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  return (
    <>
      <div className="header-top">
        <ul>
          <li>
            <Link to="/Livecasino" className="">
              Live Casino
            </Link>
          </li>
          <li>
            {/* <Link to="/Fantasy" className=""> */}
            <Link to="#" className="">
              Fantasy Games
            </Link>
          </li>
        </ul>
      </div>
      {show && broadcast.length > 0 && (
        <div className="custom-modal">
          <div className="modal_width">
            <div className="toheading_bg  d-flex justify-content-between">
              <h3 id="modalTitle" className="d-flex gap-2 bellicon">
                {" "}
                <span className="position-relative">
                  <IoMdNotifications />
                  <Badge bg="success" className="countnotification">
                    {Broadcastlengtrh}
                  </Badge>
                </span>{" "}
                Protect Your Account: Verify Website URLs and enable
                multi-factor authentication (MFA) to guard against phishing and
                unauthorized access.
              </h3>
            </div>
            <Slider {...sliderSettings} nav={false}>
              {broadcast.length === 1
                ? Array(3)
                    .fill(broadcast[0])
                    .map((item, index) => (
                      <div key={index} className="modal_item">
                        <div className="modal_header d-flex justify-content-between">
                          <h3 id="modalTitle">
                            {item.title || "Default Title"}
                          </h3>
                          {Broadcastlengtrh > 0 ? (
                            <button
                              className="close_btn"
                              onClick={() => {
                                Seenbroadcast(item.id);
                              }}
                              aria-label="Close"
                            >
                              &times;
                            </button>
                          ) : (
                            <button
                              className="close_btn"
                              onClick={() => {
                                handleClose();
                                Seenbroadcast(item.id);
                              }}
                              aria-label="Close"
                            >
                              &times;
                            </button>
                          )}
                        </div>
                        <div className="modal_body" id="modalDescription">
                          <img
                            src={`${base_url}/${item.media}`}
                            width={100}
                            height={100}
                            alt={item.title || "Modal"}
                          />
                          <p>{item.description || "Default Description"}</p>
                        </div>
                      </div>
                    ))
                : broadcast.map((item, index) => (
                    <div key={index} className="modal_item">
                      <div className="modal_header d-flex justify-content-between">
                        <h3 id="modalTitle">{item.title || "Default Title"}</h3>
                        {Broadcastlengtrh > 0 ? (
                          <button
                            className="close_btn"
                            onClick={() => {
                              Seenbroadcast(item.id);
                            }}
                            aria-label="Close"
                          >
                            &times;
                          </button>
                        ) : (
                          <button
                            className="close_btn"
                            onClick={() => {
                              handleClose();
                              Seenbroadcast(item.id);
                            }}
                            aria-label="Close"
                          >
                            &times;
                          </button>
                        )}
                      </div>
                      <div className="modal_body" id="modalDescription">
                        <img
                          src={`${base_url}/${item.media}`}
                          width={100}
                          height={100}
                          alt={item.title || "Modal"}
                        />
                        <p>{item.description || "Default Description"}</p>
                      </div>
                    </div>
                  ))}
            </Slider>
          </div>
        </div>
      )}
        <div className="whatsappicon">
          <a href={whatsapp} target="_blank">
            <img src={whatsappicon} alt="whatsapp" />
          </a>
          24x7
        </div>
    </>
  );
}
