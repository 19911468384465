import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
function Bethistory() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [betHistoryData, setBetHistoryData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedStatus, setSelectedStatus] = useState('');

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reportType, setReportType] = useState(1); 
  const filteredData = betHistoryData.filter(item => {

    const isStatusMatch = !selectedStatus || item.matched_status === selectedStatus;
    return isStatusMatch;
  });
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleFilterReset =  () => {
    setFromDate("")
    setToDate("")
  fetchDataFromAPI();
 window.location.reload();
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleEntriesChange = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };
  const navigate = useNavigate();
  const fetchDataFromAPI = async () => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl =  `${process.env.REACT_APP_API_URL}bet-history?start_date=${fromDate}&end_date=${toDate}`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data from the API");
      }
      const data = await response.json();
      //  alert(data.data);
      console.log(data.data);
      setBetHistoryData(data.data);
      console.log(data.data)
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, []);
  const handleFilterChange =  () => {
    fetchDataFromAPI();
  };
 


  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  const tabs = document.querySelectorAll(".tabthird");

  function tabify(tab) {
    const tabList = tab.querySelector(".tab__listthird");

    if (tabList) {
      const tabItems = [...tabList.children];
      const tabContent = tab.querySelector(".tab__contentthird");
      const tabContentItems = [...tabContent.children];
      let tabIndex = 0;

      tabIndex = tabItems.findIndex((item, index) => {
        return [...item.classList].indexOf("is--activethird") > -1;
      });

      tabIndex > -1 ? (tabIndex = tabIndex) : (tabIndex = 0);

      function setTab(index) {
        tabItems.forEach((x, index) => x.classList.remove("is--activethird"));
        tabContentItems.forEach((x, index) =>
          x.classList.remove("is--activethird")
        );

        tabItems[index].classList.add("is--activethird");
        tabContentItems[index].classList.add("is--activethird");
      }

      tabItems.forEach((x, index) =>
        x.addEventListener("click", () => setTab(index))
      );
      setTab(tabIndex);
      tab
        .querySelectorAll(".tabthird")
        .forEach((tabContent) => tabify(tabContent));
    }
  }

  tabs.forEach(tabify);

  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours.toString().padStart(2, '0');
  
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
  };
  
  const handleLinkClick = () => {
    navigate('/Home');
  };
  
  return (
    <>
      {/* <div
        class="card-header tab_first d-flex"
        style={{ height: "55px" }}
      ></div> */}

      <section className="bethistory">
        <main id="main" class="main">
            <div class="accountstatement d-flex justify-content-between">
              <h5 className="text-white">Settled Bets </h5>
              <button className="text-light btn bg-success text-info"  onClick={handleLinkClick}>Back</button>
            </div>

            <div className="account_statement mt-2">
              <div className="container-fluid">
                <div className="row align-items-end mb-2">
                  <div className="col-md-4">
                     <div className="d-flex justify-content-between align-items-end">
                  <Form.Select className="selectbet" aria-label="Default select example">
                {/* <option>Sport Type</option> */}
                <option value="1">Cricket</option>
                {/* <option value="2">Tennis</option>
                <option value="3">FootBall</option> */}
             
              </Form.Select>
              <Form.Select className="selectbet mx-1" aria-label="Default select example" onChange={handleStatusChange}>
  <option value="">Bet Status</option>
  <option value="matched">Matched</option>
  <option value="unmatched">Unmatched</option>
</Form.Select>
              </div>
                  </div>
                  <div class="col-md-8">
                    <form id="search" role="form" className="mb-0"> 
                      <div class="row d-flex align-items-end">
                        <div class="col-md-4 col-6">
                          <label>From Date</label>
                          <input
                            type="date"
                            class="form-custum"
                         
                            id="dateFrom"
                            value={fromDate} onChange={(e) => setFromDate(e.target.value)}
                          />
                        </div>

                        <div class="col-md-4 col-6">
                          <label>To Date</label>
                          <input
                            type="date"
                            class="form-custum"
                        
                            id="dateTo"
                            value={toDate} onChange={(e) => setToDate(e.target.value)}
                          />
                        </div>

                        <div class="col-md-4 col-12 d-flex align-items-flex-end gap-2 mt-3">
                          <button
                            class="btn_custom btn bg-color"
                            type="button"
                            id="getJsonSrc"
                            onClick={handleFilterChange}
                          >
                            {/* <i class="fa-solid fa-magnifying-glass pr-5"></i> */}
                            Go
                          </button>
                          <button
                            class="btn_custom btn bg-color"
                            type="button"
                            id="getJsonSrc"
                            onClick={handleFilterReset}
                          >
                            {/* <i class="fa-solid fa-magnifying-glass pr-5"></i> */}
                            Reset
                          </button>
                        </div>
                      </div>
                    </form>
                    
                  </div>

                  <div className="entries-selector text-light d-flex gap-1 mt-2 align-items-center">
            <span>Show :</span>
            <select
              id="entries"
              value={itemsPerPage}
              onChange={handleEntriesChange}
              className="bg-success text-light p-2  col-md-1"

            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </select>
            {/* <span>entries</span> */}
          </div>
                  <div className="col-md-12 table_details mt-3">
                    
                                <div className="table-responsive">
                                  <table className="table profit-loss-table accountstmt">
                                    <thead>
                                      <tr>
                                        <th className="col-xs-1" data-field="date">
                                       S.No.	
                                        </th>
                                        <th className="col-xs-1" data-field="date">
                                        Event Type	
                                        </th>
                                        <th
                                          className="col-xs-1"
                                          data-field="operator"
                                        >
                                          Event Name
                                        </th>
                                        <th className="col-xs-2" data-field="type">
                                          Mobile Number
                                        </th>
                                        <th
                                          className="col-xs-2"
                                          data-field="subType"
                                        >
                                          Runner Name
                                        </th>
                                        <th
                                          className="col-xs-5"
                                          data-field="message"
                                        >
                                          Bet Type
                                        </th>
                                        <th
                                          className="col-xs-5"
                                          data-field="message"
                                        >
                                          User Rate	
                                        </th>
                                        <th
                                          className="col-xs-5"
                                          data-field="message"
                                        >
                                         Amount
                                        </th>
                                        <th
                                          className="col-xs-5"
                                          data-field="message"
                                        >
                                         Place Date	
                                        </th>
                                        <th className="col-xs-5" data-field="message">
                                        Match Date

                                 </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {currentItems && currentItems.map((item,index) => (
                                        <tr key={index}>
                                            <td>{indexOfFirstItem  + index  + 1}</td>
                                          <td>{item.event_id}</td>
                                          <td>
                                            {item.event_name
                                              ? item.event_name.name
                                              : "NULL"}
                                          </td>
                                          <td>
                                            {item.user
                                              ? item.user.mobile
                                              : "NULL"}
                                          </td>
                                          <td>
                                          {item.bet_on === "lay" ? (
                                            `${item.team} / ${item.odd} /  no`
                                          ) : item.bet_on === "back" ? (
                                            `${item.team} / ${item.odd} /  yes`
                                          ) : (
                                            `${item.team} / ${item.odd} / ${item.bet_on}`
                                          )}
                                        </td>
                                          <td>{item.bet_type}</td>
                                          <td>{item.total}</td>
                                          <td>{item.stake}</td>
                                          <td>{formatDate(item.created_at)}</td>
                                          <td>{formatDate(item.event_name && item.event_name.created_at)}</td> 
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                                <div className="pagination">
                                  {Array.from({ length: totalPages }, (_, i) => (
                                    <button
                                      key={i}
                                      onClick={() => handlePageChange(i + 1)}
                                      className={currentPage === i + 1 ? "active" : ""}
                                    >
                                      {i + 1}
                                    </button>
                                  ))}
                                      </div>
                              </div>
                              
                            </div>
                          </div>
                          
                       
            </div>
        </main>
      </section>
    </>
  );
}

export default Bethistory;
