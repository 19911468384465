import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
function Accountstatement() {
  // const currentDate = new Date();
  // const nextDay = new Date(currentDate);
  // nextDay.setDate(currentDate.getDate() + 1);
  // const nextdays = nextDay.toISOString().split('T')[0];
  // console.warn(nextdays)
  // const [fromDate, setFromDate] = useState('');
  // const [toDate, setToDate] = useState("");
  

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reportType, setReportType] = useState(1); 

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleEntriesChange = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };


  const handleFilterChange = async () => {
    // Fetch data with applied filters
    await fetchDataFromAPI();
  };
  const handleFilterReset =  () => {
    setFromDate("")
    setToDate("")
  fetchDataFromAPI();
 window.location.reload();
  };

  const fetchDataFromAPI = async () => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl = `${process.env.REACT_APP_API_URL}account-statement`;

      const response = await fetch(`${apiUrl}?start_date=${fromDate}&end_date=${toDate}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      });
    
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();

      if (responseData.statusCode === 200) {
        const statementData = responseData.data.Stetment || [];
        console.log(statementData);
        setFilteredData(statementData);
      } else {
        throw new Error(
          responseData.message || "Failed to fetch data from the API"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, []);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours.toString().padStart(2, '0');
  
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
  };
  const handleLinkClick = () => {
    navigate('/Home');
  };

  return (
    <div>
      <>
        {/* <div className="card-header tab_first d-flex" style={{ height: 55 }}></div> */}
        <section className="bethistory">
          <main id="main" className="main">
            <div className="container-fluid">
              <div className="accountstatement d-flex justify-content-between align-items-center">
                <h5 className="text-white">Account Statement</h5>
                <button className="text-light btn bg-success text-info" onClick={handleLinkClick}>Back</button>
              </div>
              <div className="account_statement">
                  <form id="search" role="form" style={{}}>
                    <div className="row d-flex align-items-end">
                      {/* <div class="col-md-2 col-12 paddingsm-0">
                        <Form.Select className="form-select" aria-label="Default select example">
                          <option>Alll</option>
                          <option value="1">Profit/Loss</option>
                          <option value="2">Settlement</option>

                        </Form.Select>
                      </div> */}
                      <div className="col-md-2 col-6 ">
                        <label>From Date</label>
                        <input type="date" className="form-custum" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                      </div>
                      <div className="col-md-2 col-6">
                        <label>To Date</label>
                        <input type="date" className="form-custum" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                      </div>
                      <div className="col-md-2 d-flex align-items-flex-end mt-3">
                        <button className="btn_custom bg-color d-flex align-items-center bg-color-section btn text-light" type="button" id="getJsonSrc"    onClick={handleFilterChange}> Go</button>
                        <button className="btn_custom bg-color d-flex align-items-center bg-color-section btn text-light mx-1" type="button" id="getJsonSrc" onClick={handleFilterReset}>Reset</button>
                      </div>
                      <div className="col-md-4">
                        <table className="table-balance">
                          <tbody>
                            <tr>
                              <td className="text-white">Opening Balance	</td>
                              <td className="text-white">00.00</td>
                            </tr>
                            <tr>
                              <td className="text-white">Closing Balance</td>
                              <td className="text-white">00.00</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </form>
                  <div className="entries-selector text-light d-flex gap-1 mt-2 align-items-center">
            <span>Show :</span>
            <select
              id="entries"
              value={itemsPerPage}
              onChange={handleEntriesChange}
              className="bg-success text-light p-2  col-md-1"

            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </select>
            {/* <span>entries</span> */}
          </div>
              </div>
            </div>
            <div className="margin-top-bottom-20">
              <div className="table-responsive">
                <table id="dataTable" className="table accountstmt" data-pagination="true" data-page-size={5} data-page-list="5, 10, All"
                  data-search="true" data-search-align="left" data-striped="true" data-show-refresh="true"
                  data-sort-name="date" data-sort-order="desc" data-search-text="Narrow results"
                >
                  <thead className="table_account display_change thead-success">
                    <tr>
                     
                      <th className="col-xs-1" data-field="date">
                        S.NO
                      </th>
                      <th className="col-xs-1" data-field="date">
                        Date
                      </th>
                      <th className="col-xs-1" data-field="operator">
                        Credit
                      </th>
                      <th className="col-xs-2" data-field="type">
                        Debit
                      </th>
                      <th className="col-xs-5" data-field="message">
                        Balance
                      </th>
                      <th className="col-xs-5" data-field="message">
                        Remark
                      </th>
                    </tr>

                  </thead>
                  <tbody>
                    {currentItems.map((item, index) => (
                      <tr key={index}  >
                               <td>{indexOfFirstItem  + index  + 1}</td>
                        <td>{formatDate(item.created_at)}</td>
                        <td style={{ color: 'green' }}>{item.credit}</td>
                        <td style={{ color: 'red' }}>{item.debit}</td>
                        <td style={{ color: 'green' }}>{item.total_amount}</td>
                        <td className="d-flex">{item.remark}-{item.bet_rate} - <span style={{ color: item.tr_status === 'Success' ? 'green' : 'red' }}>{item.tr_status}</span></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="pagination">
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              className={currentPage === i + 1 ? "active" : ""}
            >
              {i + 1}
            </button>
          ))}
              </div>
            </div>
            </div>
          </main>
        </section>
      </>
    </div>
  )
}
export default Accountstatement
