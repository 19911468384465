import React, { useState, useEffect } from 'react';
import { Badge, Button, Modal, Form } from 'react-bootstrap';
import { PiTelevisionSimpleFill } from "react-icons/pi";
import Indexslider from './Sliderindex';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import axios from 'axios';

export default function Index() {
    const [show, setShow] = useState(false);
    const [Name, setName] = useState();
    const [buttonValues, setButtonValues] = useState([]);
    const [loading, setLoading] = useState(true); // State for loading
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const userid = localStorage.getItem("userid");
    const selectedSeriesId = localStorage.getItem("selectedSeriesId");
    const [matches, setMatches] = useState([]);
    const [seriesData, setSeriesData] = useState([]);
    const [sliderData, setSliderData] = useState([]);
    const [sliderImg, setSliderImg] = useState([]);
    const [betamountnew, setBetamount] = useState();
    const [bgcolor, setBgcolor] = useState();

    useEffect(() => {
        if (!token || !userid) {
            navigate('/');
        }
    }, [token, userid, navigate]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const betamount = (index) => {
        setBetamount(index);
    };

    const betchangeamt = (e) => {
        setBetamount(e.target.value);
    };

    const color1 = '#72bbef';
    const color2 = '#f994ba';

    const bgchangecolor = (color, name) => {
        setName(name);
        setBgcolor(color);
    };

    const formatDateTime = (dateTimeString) => {
        const options = {
            day: 'numeric',
            month: 'short',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };
        const formattedDate = new Date(dateTimeString).toLocaleString(undefined, options);
        return formattedDate.replace(/\b(\d{1,2}:\d{2})\s+(AM)\b/i, '$1 PM');
    };

    // useEffect(() => {
    //     const fetchSeriesData = async () => {
    //         setLoading(true); // Set loading to true before fetching data
    //         try {
    //             const axiosInstance = axios.create({
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             });

    //             const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}series-ids?sport_id=4`);
    //             const data = response.data.data;

    //             const seriesMap = new Map();

    //             data.matches.forEach(match => {
    //                 match.series.forEach(series => {
    //                     if (!seriesMap.has(series.series_id)) {
    //                         seriesMap.set(series.series_id, {
    //                             ...series,
    //                             matches: [],
    //                         });
    //                     }
    //                     seriesMap.get(series.series_id).matches.push(match);
    //                 });
    //             });

    //             const filteredSeriesData = Array.from(seriesMap.values()).filter(
    //                 series => series.series_id == selectedSeriesId
    //             );

    //             setSeriesData(filteredSeriesData);
    //             console.warn("filteredSeriesData",filteredSeriesData)
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //         } finally {
    //             setLoading(false); // Set loading to false after fetching data
    //         }
    //     };

    //     fetchSeriesData();
    // }, [selectedSeriesId, token]);
        const location = useLocation();
        
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const seriesIds = queryParams.get('seriesId');
        const sportId = queryParams.get('sportId');
        const token = localStorage.getItem("token");
      const sportID = localStorage.getItem("sportId");

      const seriesId = localStorage.getItem("seriesId");
      const eventID = localStorage.getItem("event_id");

      
   
    
      const axiosInstance = axios.create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    
      axiosInstance
      // .get(`https://leobook.in/Admin/api/v1/series-ids?sport_id=${sportID}`)
      .get(`${process.env.REACT_APP_API_URL}series-ids-index?sport_id=${sportId}&series_id=${seriesIds}`)
      .then((response) => {
        const data = response.data.data;
        const seriesMap = new Map();

        data.matches.forEach(match => {
          match.series.forEach(series => {
            if (!seriesMap.has(series.series_id)) {
              seriesMap.set(series.series_id, {
                ...series,
                matches: []
              });
            }
            seriesMap.get(series.series_id).matches.push(match);
          });
        });
        // setSeriesData(Array.from(seriesMap.values()));

        // console.warn("oio",Array.from(seriesMap.values()));
        // data.matches.forEach(match => {
        //   var resdata = [
        //     'created_at' = match.created_at,
        //     'series_id' = match.series_id,
        //     'series' = [],
        //   ];

        //   match.series.forEach(series => {
        //     console.warn(series.name);
            
        //   });
          // });
          setLoading(false);
        const resdata1 = [];

const allSeriesData = data.matches.map(match => {
  const resdata = {
    created_at: match.created_at,
    series_id: match.series_id,
    series: []
  };

  match.series.forEach(series => {
    if (match.series_id == series.series_id) {
      const inner = {
        id: series.id,
        date_time: series.date_time,
        event_id: series.event_id,
        market_id: series.market_id,
        name: series.name,
        series_id: series.series_id,
      };
      resdata.series.push(inner);
    }
  });

  resdata1.push(resdata); // Push the completed resdata object to resdata1.match
  return resdata1;
});


        setSeriesData(data.matches); // Sets data for all matches
        console.warn('ty', allSeriesData);
      
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [location]);

    useEffect(() => {
        const getButtonValues = async () => {
            try {
              
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}get-button-value`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                if (response.ok) {
                    const rawResponse = await response.json();

                    const cleanedData = rawResponse.data.map((item) =>
                        item.replace(/"/g, "")
                    );
                    const cleanedResponse = { ...rawResponse, data: cleanedData };
                    if (cleanedResponse.status_code === 1) {
                        setButtonValues(cleanedResponse.data);

                    } else {
                        console.error("Invalid data format:", cleanedResponse);
                    }
                } else {
                    console.error("Failed to fetch button values");
                }
            } catch (error) {
                console.error("Error fetching button values:", error);
            }
        };

        getButtonValues();
    }, [token]);

    const onclickmatch = (seriesId, event_id, name) => {
        // navigate("/Bettingpage");

        localStorage.setItem("seriesId", seriesId);
        localStorage.setItem("event_id", event_id);
        localStorage.setItem("name", name);
    };

    return (
        <div className="index_bet">
            <Indexslider />
            <div className="bet-table-header sport4 d-flex justify-content-between">
                <div className="game-title">
                    <i className="d-icon icon-4" /> <span>Cricket</span>
                </div>
                <div className="point-title d-none-mobile">1</div>
                <div className="point-title d-none-mobile">X</div>
                <div className="point-title d-none-mobile">2</div>
            </div>
            <div className="bet-table-body">
                {loading ? (
                    <div className='nodatafound'>
                        <div className="loader-container">
                            <div className="loader">
                                <div className="ball"></div>
                                <div className="ball"></div>
                                <div className="ball"></div>
                                <div className="ball"></div>
                            </div>
                        </div>
                    </div>
                ) : seriesData && seriesData.length > 0 ? (
                    seriesData.map((match) => (
          <React.Fragment key={match.series_id}>
             {match.series.map((series) => {
              const currentDate = new Date();
                    const options = {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                    };
                    const matchDate = new Date(match.created_at);
                    const isToday = matchDate.toDateString() === currentDate.toDateString();
               const isLive = isToday && currentDate >= matchDate;
               
                return (
                                    <div className="row_match" key={match.event_id}>
                                        <div className="d-flex justify-content-between">
                                            <div className='d-flex width_match'>
                                                 <Link to={`/Bettingpage?id=${series.id}&eventid=${series.event_id}&matchid=${series.market_id}&name=${series.name}`} className="text-white">
                                                    <div className='d-flex gap-3'>
                                                        <div className="upsale-button">
                                                            Live
                                                        </div>
                                                        <div className='matchname' onClick={() => onclickmatch(series.series_id, match.event_id, series.name)}>
                                                            {series.name}
                                                            <span className="date d-block matchdate">{formatDateTime(series.date_time)}</span>
                                                        </div>
                                                    </div>
                                                </Link>

                                                {isLive ? (
                                                    <div className="d-flex align-items-center">
                                                        <span className="live-text" style={{ color: '#000', paddingLeft: '10px' }}></span>
                                                        <span className="activegreen mx-2" />
                                                    </div>
                                                ) : null}
                                                <div>
                                                    <span className="d-flex align-items-center gap-2">F1 F BM <PiTelevisionSimpleFill /></span>
                                                </div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className='d-flex gap-1 point-title'>
                                                    <div className="box1" onClick={() => { bgchangecolor(color1, series.name);  }} style={{ "--i": "#72bbef" }}>1.00</div>
                                                    <div className="box2" onClick={() => { bgchangecolor(color2, series.name);  }} style={{ "--i": "#f994ba" }}>1.00</div>
                                                </div>
                                                <div className='d-flex gap-1 point-title'>
                                                    <div className="box1 bl-box" onClick={() => { bgchangecolor(color1, series.name);  }} style={{ "--i": "" }}>1.00</div>
                                                    <div className="box2 bl-box" onClick={() => { bgchangecolor(color1, series.name);  }} style={{ "--i": "" }}>1.00</div>
                                                </div>
                                                <div className='d-flex gap-1 point-title'>
                                                    <div className="box1" onClick={() => { bgchangecolor(color1, series.name);  }} style={{ "--i": "#72bbef" }}>1.00</div>
                                                    <div className="box2" onClick={() => { bgchangecolor(color2, series.name);  }} style={{ "--i": "#f994ba" }}>1.00</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
              
           })}

          </React.Fragment>
        ))
                ) : (
                    <div className="nodatafound">
                        <div>No Data Found</div>
                    </div>
                )}
            </div>

          
        </div>
    );
}
